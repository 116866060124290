import * as React from 'react'
import {
  Show,
  TextField,
  BooleanField,
  TranslatableFields,
  SimpleShowLayout,
  NumberField,
  ListButton,
  TopToolbar,
} from 'react-admin'
import PropTypes from 'prop-types'

const ThematicShowActions = () => {
  return (
    <TopToolbar>
      <ListButton basePath="/thematics" />
    </TopToolbar>
  )
}

ThematicShowActions.propTypes = {
  data: PropTypes.any,
  basePath: PropTypes.any,
}

const ThematicShow = props => {
  return (
    <Show actions={<ThematicShowActions />} {...props}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <BooleanField
          source="status"
          looseValue={true}
          valueLabelTrue="Active"
          valueLabelFalse="Not Active"
        />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale="es"
          source="label"
        >
          <TextField fullWidth source="label" />
        </TranslatableFields>
      </SimpleShowLayout>
    </Show>
  )
}

export default ThematicShow
