import envConfig from '../environments'
import * as auth from '../auth'

const authProvider = {
  login: ({ email, password }) => {
    const request = new Request(envConfig.backendUrl + '/authenticate', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(auth => {
        localStorage.setItem('auth', JSON.stringify(auth.data))
      })
      .then(() => auth.identity())
      .catch(error => {
        throw new Error(error.message)
      })
  },
  logout: () => {
    localStorage.removeItem('auth')
    localStorage.removeItem('identity')
    return Promise.resolve()
  },
  checkError: error => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth')
      localStorage.removeItem('identity')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
  },
  getIdentity: () => {
    try {
      const { id, userName, country, role } = JSON.parse(
        localStorage.getItem('identity'),
      )
      return Promise.resolve({ id, fullName: userName, country, role })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getPermissions: () => {
    try {
      const { role, country } = JSON.parse(localStorage.getItem('identity'))
      const userPermissions = auth.permissions[role][country.toLowerCase()]
      return Promise.resolve({ ...userPermissions })
    } catch (error) {
      return Promise.resolve([])
    }
  },
}

export default authProvider
