import * as React from 'react'
import {
  TranslatableFields,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  useGetIdentity,
  NumberField,
  TextField,
} from 'react-admin'

const ThematicEdit = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>

  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberField source="id" disabled />
        <BooleanInput source="status" />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale={identity.country.toLowerCase()}
          source="label"
        >
          <TextInput fullWidth source="label" />
        </TranslatableFields>
        <TextField source="country" disabled />
      </SimpleForm>
    </Edit>
  )
}

export default ThematicEdit
