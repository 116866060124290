import * as React from 'react'
import {
  TextField,
  TranslatableFields,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  useGetIdentity,
  TopToolbar,
  ListButton,
} from 'react-admin'
import * as PropTypes from 'prop-types'
import { useLocation } from 'react-router'

const ResponseEditActions = ({ data, resource }) => {
  const responseListPath = `/questions/${data.questionId}/show/${resource}`
  return (
    <TopToolbar>
      <ListButton basePath={responseListPath} />
    </TopToolbar>
  )
}

ResponseEditActions.propTypes = {
  data: PropTypes.any,
  resource: PropTypes.any,
}

const ResponseEdit = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  const location = useLocation()

  if (identityLoading) return <>Loading...</>
  const responseLabel = 'label.' + identity.country.toLowerCase()

  const questionId =
    location.state && location.state.record
      ? location.state.record.questionId
      : undefined

  const redirect = questionId
    ? `/questions/${questionId}/show/responses`
    : false

  return (
    <Edit actions={<ResponseEditActions />} {...props}>
      <SimpleForm redirect={redirect}>
        <TextField source="id" disabled />
        <TextField source="questionId" disabled />
        <ReferenceInput
          translateChoice={false}
          label="Next Question"
          source="nextQuestionId"
          reference="questions"
          filter={{ country: identity.country }}
          allowEmpty
        >
          <SelectInput optionText={responseLabel} />
        </ReferenceInput>
        <BooleanInput source="status" />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale="es"
          source="label"
        >
          <TextInput fullWidth source="label" label="Label" />
        </TranslatableFields>
        <BooleanInput source="isSelected" />
      </SimpleForm>
    </Edit>
  )
}

export default ResponseEdit
