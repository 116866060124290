const customFrenchMessages = {
  surveys: {
    language: {
      es: 'Español',
      en: 'Inglés',
      it: 'Italiano',
      fr: 'Frances',
    },
    resources: {
      campaign: 'Campagne',
      campaigns: 'Campagnes',
      user: 'Utilisateur',
      users: 'Utilisateurs',
      question: 'Question',
      questions: 'Questions',
      response: 'Répondre',
      responses: 'Réponses',
      coreg: 'Coreg',
      coregs: 'Coregs',
      thematics: 'Thematics',
      thematic: 'Thematic',
    },
    actions: {
      show_question: 'Afficher la question',
      edit_question: 'Modifier la question',
      add_response: 'Ajouter une réponse',
      new_coregs: 'Ajouter Coreg',
      create_coregs: 'Create Coreg',
      new_thematics: 'Add Thematic',
      create_thematics: 'Create Thematic',
    },
    common: {
      status: 'Estado',
      active: 'Actif',
      desactive: 'Baja',
      vip: 'Vip',
      standard: 'Standard',
      identify: 'Identifiant',
      name: 'Name',
      registered: 'Registered',
      registration_date: 'Registration date',
      origin: 'Origine',
      boolean: {
        yes: 'Yes',
        no: 'No',
      },
    },
    messages: {
      welcome: 'Welcome to the administration',
      update_success: 'The update finished successfully',
      update_failure: 'An error occurred during update',
      user_deactivated_by_admin: 'User deactivated by an administrator',
      data_loading: 'Loading data ...',
      data_error: 'Error loading data',
      chart_data_loading: 'Loading chart data ...',
      chart_data_error: 'Error loading chart data',
      forgot_password: 'Forgot password?',
      reset_password: 'Reset password',
      reset_password_text:
        "Enter the email associated with your account and we'll send an email with instructions to reset your password.",
      confirm_password: 'Confirm password',
      create_password: 'Create new password',
      create_password_text: 'Please enter a new password',
      change_password: 'Change password',
      change_password_success: 'Password changed successfully',
      change_password_error: 'Error on reset password',
      change_password_link_error: 'Link not valid',
      forgot_password_success:
        'An email will be sent with the instructions to reset your password',
      forgot_password_error: 'Error requesting the password change',
      send_instructions: 'Send instructions',
      exceptions: {
        ResourceUrlExistException:
          'Une ressource avec cette URL existe déjà (%{url})',
      },
    },
    validations: {
      confirmPassword: 'Password must be the same as the confirm password',
    },
  },
}

export default customFrenchMessages
