import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  BooleanInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  useGetIdentity,
  TopToolbar,
  ListButton,
} from 'react-admin'
import * as React from 'react'
import * as PropTypes from 'prop-types'

const CampaignCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

CampaignCreateActions.propTypes = {
  basePath: PropTypes.any,
}

export const CampaignsCreate = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const country = identity.country
  const questionSource = 'label.' + country.toLowerCase()

  return (
    <Create actions={<CampaignCreateActions />} {...props}>
      <TabbedForm
        submitOnEnter={false}
        syncWithLocation={false}
        redirect="edit"
      >
        <FormTab label="Summary">
          <TextInput source="name" validate={required()} />
          <TextInput source="country" defaultValue={country} disabled />
          <BooleanInput source="status" defaultValue={false} />
        </FormTab>
        <FormTab label="Questions">
          <ArrayInput source="questionIds">
            <SimpleFormIterator>
              <ReferenceInput
                translateChoice={false}
                label="Question"
                source=""
                reference="questions"
                filter={{ country: identity.country, next_question_id: null }}
              >
                <SelectInput optionText={questionSource} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
