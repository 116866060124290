const customEnglishMessages = {
  surveys: {
    language: {
      es: 'Spanish',
      en: 'English',
      it: 'Italian',
      fr: 'French',
    },
    resources: {
      campaign: 'Campaign',
      campaigns: 'Campaigns',
      user: 'User',
      users: 'Users',
      question: 'Question',
      questions: 'Questions',
      response: 'Response',
      responses: 'Responses',
      coreg: 'Coreg',
      coregs: 'Coregs',
      thematics: 'Thematics',
      thematic: 'Thematic',
    },
    actions: {
      show_question: 'Show question',
      edit_question: 'Edit question',
      add_response: 'Add response',
      new_coregs: 'Add Coreg',
      create_coregs: 'Create Coreg',
      new_thematics: 'Add Thematic',
      create_thematics: 'Create Thematic',
    },
    common: {
      status: 'Status',
      active: 'Active',
      desactive: 'Baja',
      vip: 'Vip',
      standard: 'Standard',
      identify: 'Id',
      name: 'Name',
      registered: 'Registered',
      registration_date: 'Registration date',
      origin: 'Origin',
      boolean: {
        yes: 'Yes',
        no: 'No',
      },
    },
    messages: {
      welcome: 'Welcome to the administration',
      update_success: 'The update finished successfully',
      update_failure: 'An error occurred during update',
      user_deactivated_by_admin: 'User deactivated by an administrator',
      data_loading: 'Loading data ...',
      data_error: 'Error loading data',
      chart_data_loading: 'Loading chart data ...',
      chart_data_error: 'Error loading chart data',
      forgot_password: 'Forgot password?',
      reset_password: 'Reset password',
      reset_password_text:
        "Enter the email associated with your account and we'll send an email with instructions to reset your password.",
      confirm_password: 'Confirm password',
      create_password: 'Create new password',
      create_password_text: 'Please enter a new password',
      change_password: 'Change password',
      change_password_success: 'Password changed successfully',
      change_password_error: 'Error on reset password',
      change_password_link_error: 'Link not valid',
      forgot_password_success:
        'An email will be sent with the instructions to reset your password',
      forgot_password_error: 'Error requesting the password change',
      send_instructions: 'Send instructions',
      exceptions: {
        ResourceUrlExistException: 'Resource URL already exists (%{url})',
      },
    },
    validations: {
      confirmPassword: 'Password must be the same as the confirm password',
    },
  },
}

export default customEnglishMessages
