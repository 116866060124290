import * as React from 'react'
import {
  Create,
  SimpleForm,
  TranslatableFields,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  ListButton,
  required,
} from 'react-admin'

import { useGetIdentity } from 'react-admin'
import { useLocation } from 'react-router'
import * as PropTypes from 'prop-types'

const ResponseCreateActions = ({ redirect }) => {
  return (
    <TopToolbar>
      <ListButton basePath={redirect} />
    </TopToolbar>
  )
}

ResponseCreateActions.propTypes = {
  redirect: PropTypes.any,
}

const ResponseCreate = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  const location = useLocation()

  if (identityLoading) return <>Loading...</>
  const country = identity.country
  const questionSource = 'label.' + country.toLowerCase()

  const questionId =
    location.state && location.state.record
      ? location.state.record.questionId
      : undefined

  const redirect = questionId
    ? `/questions/${questionId}/show/responses`
    : false

  return (
    <Create actions={<ResponseCreateActions redirect={redirect} />} {...props}>
      <SimpleForm initialValues={{ questionId }} redirect={redirect}>
        <ReferenceInput
          translateChoice={false}
          label="Question"
          source="questionId"
          reference="questions"
          filter={{ country: identity.country }}
        >
          <SelectInput optionText={questionSource} />
        </ReferenceInput>
        <ReferenceInput
          translateChoice={false}
          source="nextQuestionId"
          reference="questions"
          filter={{ country: identity.country }}
          allowEmpty
        >
          <SelectInput optionText={questionSource} />
        </ReferenceInput>
        <BooleanInput source="status" initialValue={false} />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale="es"
          source="label"
        >
          <TextInput fullWidth source="label" validate={[required()]} />
        </TranslatableFields>
        <BooleanInput source="isSelected" initialValue={false} />
      </SimpleForm>
    </Create>
  )
}

export default ResponseCreate
