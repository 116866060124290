import { buildFullAccessFor } from 'ra-auth-acl'

const permissions = {
  admin: {
    es: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
    fr: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
    it: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
  },
  operations: {
    es: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
    fr: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
    it: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
  },
  product: {
    es: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
    fr: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
    it: {
      ...buildFullAccessFor([
        'campaigns',
        'user',
        'questions',
        'responses',
        'thematics',
        'coregs',
      ]),
    },
  },
}

export default permissions
