import * as React from 'react'
import {
  List,
  TextField,
  BooleanField,
  NumberField,
  DateField,
  EmailField,
  SelectField,
  TextInput,
} from 'react-admin'
import CustomizableDatagrid from 'ra-customizable-datagrid'

const userFilters = [
  <TextInput key="email" source="email" />,
  <TextInput key="phone" source="phone" />,
]

const UserList = props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={userFilters}
    >
      <CustomizableDatagrid
        defaultColumns={['id', 'status', 'email', 'name', 'surnames', 'phone']}
      >
        <NumberField source="id" sortByOrder="DESC" />
        <BooleanField source="status" />
        <EmailField source="email" />
        <TextField source="name" />
        <TextField source="surnames" />
        <NumberField source="phone" />
        <NumberField source="postalCode" />
        <SelectField
          source="gender"
          choices={[
            { id: 'M', name: 'Male' },
            { id: 'F', name: 'Female' },
          ]}
        />
        <DateField source="dob.date" label="Birthdate" />
        <TextField source="data.ip" label="Ip" />
        <TextField source="data.origen" label="Origen" />
        <BooleanField source="comercial" looseValue={true} />
        <BooleanField source="telemarketing" looseValue={true} />
      </CustomizableDatagrid>
    </List>
  )
}

export default UserList
