import * as React from 'react'
import {
  List,
  TextField,
  Datagrid,
  NumberField,
  TextInput,
  ArrayField,
  useGetIdentity,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  EditButton,
} from 'react-admin'
import { useTranslate } from 'ra-core'

const FilesFilters = [<TextInput key="name" source="name" />]

const CampaignsListActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  )
}

const CampaignList = props => {
  const translate = useTranslate()
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  return (
    <List
      {...props}
      actions={<CampaignsListActions />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'ASC' }}
      filters={FilesFilters}
    >
      <Datagrid>
        <NumberField source="id" sortByOrder="DESC" />
        <TextField source="name" />
        <ArrayField source="questionList">
          <Datagrid>
            <NumberField label="Ids" source="id" />
            <TextField
              label={translate('surveys.resources.questions')}
              source={`label.${identity.country.toLowerCase()}`}
            />
          </Datagrid>
        </ArrayField>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CampaignList
