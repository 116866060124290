const validateQuestion = values => {
  const errors = {}
  if (!values.fieldMD) {
    errors.fieldMD = ['MD Field is required']
  }
  if (!values.responseType) {
    errors.responseType = ['Type of Response is required']
  }
  if (!values.thematicId) {
    errors.thematicId = ['Thematic is required']
  }
  if (!values.country) {
    errors.country = ['Country is required']
  }
  return errors
}

export default validateQuestion
