import { AppBar as BaseAppBar } from 'react-admin'
import appLogo from '../assets/logo_lgs.png'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  logo: {
    maxWidth: '82px',
  },
})

const AppBar = props => {
  const classes = useStyles()
  return (
    <BaseAppBar {...props}>
      <img src={appLogo} alt="logo" className={classes.logo} />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </BaseAppBar>
  )
}

export default AppBar
