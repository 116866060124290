import * as React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { useGetIdentity, useLogout, Loading } from 'react-admin'
import { useTranslate } from 'ra-core'

const Dashboard = () => {
  const { identity, loading, loaded } = useGetIdentity()
  const { logout } = useLogout()
  const translate = useTranslate()

  if (loading) return <Loading />
  if (!loading && loaded && !identity) logout()
  if (!identity) return null

  return (
    <Card>
      <CardHeader
        title={translate('surveys.messages.welcome') + ', ' + identity.fullName}
      />
      <CardContent></CardContent>
    </Card>
  )
}

export default Dashboard

/*export default () => (
  <Card>
    <CardHeader title="Welcome to the administration" />
    <CardContent>Lorem ipsum sic dolor amet...</CardContent>
  </Card>
)*/
