import * as React from 'react'
import {
  Show,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  TranslatableFields,
  useGetIdentity,
  TopToolbar,
  ListButton,
  EditButton,
  DeleteWithConfirmButton,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
} from 'react-admin'
import * as PropTypes from 'prop-types'
import AddResponseButton from '../../components/AddResponseButton'
import EditResponseButton from '../../components/EditResponseButton'
import { useLocation } from 'react-router'
import { useTranslate } from 'ra-core'

const QuestionShowActions = ({ basePath, data }) => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
      <EditButton
        basePath={basePath}
        label={translate('surveys.actions.edit_question')}
        record={data}
      />
    </TopToolbar>
  )
}

QuestionShowActions.propTypes = {
  data: PropTypes.any,
  basePath: PropTypes.any,
}

const QuestionShow = props => {
  const translate = useTranslate()
  const location = useLocation()
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const questionLabel = 'label.' + identity.country.toLowerCase()
  return (
    <Show actions={<QuestionShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label={translate('surveys.resources.question')}>
          <TextField source="id" disabled />
          <ReferenceField
            label="Thematic"
            source="thematicId"
            reference="thematics"
          >
            <TextField source={questionLabel} />
          </ReferenceField>
          <ReferenceField
            translateChoice={false}
            label="Next Question"
            source="nextQuestionId"
            reference="questions"
            emptyText="-"
          >
            <TextField source={questionLabel} />
          </ReferenceField>
          <BooleanField source="status" />
          <TranslatableFields
            locales={['es', 'en', 'it', 'fr']}
            defaultLocale={identity.country.toLowerCase()}
            source="label"
          >
            <TextField fullWidth source="label" />
          </TranslatableFields>
          <TextField source="responseType" />
          <TextField source="fieldMD" label="Mdirector Field" />
          <TextField source="country" label="Country" />
        </Tab>
        <Tab label={translate('surveys.resources.responses')} path="responses">
          <ReferenceManyField
            addLabel={false}
            reference="responses"
            target="question_id"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="questionId" />
              <TextField source="nextQuestionId" emptyText="-" />
              <BooleanField source="status" />
              <TextField source={questionLabel} label="Label" />
              <BooleanField source="isSelected" />
              <EditResponseButton />
              <DeleteWithConfirmButton
                redirect={location.pathname}
                cancel="Cancel"
              />
            </Datagrid>
          </ReferenceManyField>
          <AddResponseButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default QuestionShow
