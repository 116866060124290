import { stringify } from 'query-string'
import envConfig from '../environments'
import httpClient from './httpClient'

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    }
    const url = `${envConfig.backendUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }))
  },

  getOne: (resource, params) =>
    httpClient(`${envConfig.backendUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json.data,
      }),
    ),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${envConfig.backendUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json.data }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${envConfig.backendUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }))
  },

  update: (resource, params) => {
    return httpClient(`${envConfig.backendUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json.data }))
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(
      `${envConfig.backendUrl}/${resource}?${stringify(query)}`,
      {
        method: 'PUT',
        body: JSON.stringify(params.data),
      },
    ).then(({ json }) => ({ data: json.data }))
  },

  create: (resource, params) => {
    return httpClient(`${envConfig.backendUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.data.id },
    }))
  },

  delete: (resource, params) =>
    httpClient(`${envConfig.backendUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json.data })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(
      `${envConfig.backendUrl}/${resource}/delete/?${stringify(query)}`,
      {
        method: 'DELETE',
      },
    ).then(({ json }) => ({ data: json.data }))
  },
}
