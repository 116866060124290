import * as React from 'react'
import {
  Edit,
  NumberField,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  TopToolbar,
  ListButton,
  TextField,
} from 'react-admin'

import { useGetIdentity } from 'react-admin'
import * as PropTypes from 'prop-types'

const CoregEditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

CoregEditActions.propTypes = {
  basePath: PropTypes.any,
}

const CoregsEdit = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const country = identity.country
  const questionSource = 'label.' + country.toLowerCase()
  return (
    <Edit actions={<CoregEditActions />} {...props}>
      <SimpleForm>
        <NumberField source="id" disabled />
        <ReferenceInput
          translateChoice={false}
          label="Question"
          source="questionId"
          reference="questions"
        >
          <SelectInput optionText={questionSource} />
        </ReferenceInput>
        <NumberInput label="CD Affiliate" source="cdAffiliateId" disabled />
        <NumberInput label="CD Campaign" source="cdCampaignId" />
        <BooleanInput source="status" />
        <TextField source="country" disabled />
      </SimpleForm>
    </Edit>
  )
}

export default CoregsEdit
