import React from 'react'
import { EditButton } from 'react-admin'
import * as PropTypes from 'prop-types'

const EditResponseButton = ({ record }) => (
  <EditButton
    to={{
      pathname: `/responses/${record.id}`,
      state: { record: { questionId: record.questionId } },
    }}
  />
)

EditResponseButton.propTypes = {
  record: PropTypes.object,
}

export default EditResponseButton
