import './App.css'
import * as React from 'react'
import { Admin, Layout, RouteWithoutLayout } from 'react-admin'
import Questions from './app/Questions'
import Coregs from './app/Coregs'
import Responses from './app/Responses'
import Thematics from './app/Thematics'
import Users from './app/Users'
import Campaign from './app/Campaigns'
import Dashboard from './app/Dashboard'
import LoginPage from './app/Login/LoginPage'
import { LogoutButton } from './components/LogoutButton'
import authProvider from './providers/authProvider'
import dataProvider from './providers/dataProvider'
import i18nProvider from './providers/i18nProvider'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import UserIcon from '@material-ui/icons/People'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import AppBar from './app/AppBar'
import { useTranslate } from 'ra-core'
import { ResourceWithPermissions } from 'ra-auth-acl'
import { MenuApp } from './app/MenuApp'
import ForgotPassword from './app/ResetPassword/ForgotPassword'
import ResetPassword from './app/ResetPassword/ResetPassword'

const AppLayout = props => <Layout {...props} appBar={AppBar} menu={MenuApp} />

const App = () => {
  const translate = useTranslate()
  return (
    <Admin
      title="Surveys"
      layout={AppLayout}
      dashboard={Dashboard}
      loginPage={LoginPage}
      logoutButton={LogoutButton}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customRoutes={[
        <RouteWithoutLayout
          key="forgotPassword"
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />,
        <RouteWithoutLayout
          key="resetPassword"
          exact
          path="/reset-password"
          component={ResetPassword}
        />,
      ]}
    >
      {permissions => [
        <ResourceWithPermissions
          key="campaigns"
          name="campaigns"
          permissions={permissions}
          options={{ label: translate('surveys.resources.campaigns') }}
          icon={RssFeedIcon}
          {...Campaign}
        />,
        <ResourceWithPermissions
          key="user"
          name="user"
          permissions={permissions}
          options={{ label: translate('surveys.resources.users') }}
          icon={UserIcon}
          {...Users}
        />,
        <ResourceWithPermissions
          key="questions"
          name="questions"
          permissions={permissions}
          options={{ label: translate('surveys.resources.questions') }}
          icon={QuestionAnswerIcon}
          {...Questions}
        />,
        <ResourceWithPermissions
          key="responses"
          name="responses"
          permissions={permissions}
          {...Responses}
        />,
        <ResourceWithPermissions
          key="thematics"
          name="thematics"
          permissions={permissions}
          {...Thematics}
        />,
        <ResourceWithPermissions
          key="coregs"
          name="coregs"
          permissions={permissions}
          options={{ label: translate('surveys.resources.coregs') }}
          icon={FeaturedPlayListIcon}
          {...Coregs}
        />,
      ]}
    </Admin>
  )
}

export default App
