import * as React from 'react'
import Card from '@material-ui/core/Card'
import { createMuiTheme, useRedirect } from 'react-admin'
import { useMemo } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
  CardActions,
  CardHeader,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import classnames from 'classnames'
import LockIcon from '@material-ui/icons/Lock'
import { Field, Form } from 'react-final-form'
import { useNotify, useSafeSetState, useTranslate } from 'ra-core'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import PropTypes from 'prop-types'
import qs from 'qs'
import httpClient from '../../providers/httpClient'
import envConfig from '../../environments'
import { Loading } from 'react-admin'
import { useValidResetPasswordLink } from '../../hooks/useValidResetPasswordLink'

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      width: 'min-content',
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
    form: {
      padding: '0 0 1em 0',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    formIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLogin' },
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const ResetPassword = props => {
  const { theme, location, ...rest } = props
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme])
  const classes = useStyles(props)
  const translate = useTranslate()
  const [loading, setLoading] = useSafeSetState(false)
  const redirect = useRedirect()
  const notify = useNotify()

  const { isValidLink, isValidating } = useValidResetPasswordLink(location)

  const validate = values => {
    const errors = { password: undefined, confirmPassword: undefined }

    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = translate('ra.validation.required')
    }
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = translate('surveys.validations.confirmPassword')
    }
    return errors
  }

  const submit = values => {
    setLoading(true)
    values.email = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).email

    httpClient(`${envConfig.backendUrl}/reset-password`, {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then(() => {
        notify('surveys.messages.change_password_success', {
          type: 'success',
        })
        redirect('/login')
      })
      .catch(() => {
        notify('surveys.messages.change_password_error', {
          type: 'warning',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (isValidating) {
    return <Loading />
  }
  if (!isValidLink) {
    redirect('/login')
  }
  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(classes.main)} {...rest}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <CardHeader title={translate('surveys.messages.create_password')} />
          <CardContent>
            <Typography variant="body2" component="p" color="textSecondary">
              {translate('surveys.messages.create_password_text')}
            </Typography>
            <Form
              onSubmit={submit}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <Field
                        id="password"
                        name="password"
                        component={Input}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        id="confirmPassword"
                        name="confirmPassword"
                        component={Input}
                        label={translate('surveys.messages.confirm_password')}
                        type="password"
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <CardActions>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      className={classes.button}
                    >
                      {loading && (
                        <CircularProgress
                          className={classes.formIcon}
                          size={18}
                          thickness={2}
                        />
                      )}
                      {translate('surveys.messages.change_password')}
                    </Button>
                  </CardActions>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  )
}

ResetPassword.propTypes = {
  theme: PropTypes.any,
  location: PropTypes.any,
}

export default ResetPassword
