import QuestionCreate from './QuestionCreate'
import QuestionList from './QuestionList'
import QuestionShow from './QuestionShow'
import QuestionEdit from './QuestionEdit'

export default {
  create: QuestionCreate,
  list: QuestionList,
  show: QuestionShow,
  edit: QuestionEdit,
}
