import * as React from 'react'
import {
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  usePermissions,
  useGetIdentity,
} from 'react-admin'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import UserIcon from '@material-ui/icons/People'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols'
import { useTranslate } from 'ra-core'
import { hasAccess } from 'ra-auth-acl'

export const MenuApp = props => {
  const translate = useTranslate()
  const { isLoading, permissions } = usePermissions()
  const { identity } = useGetIdentity()
  if (isLoading) return null
  if (!identity) return null
  return (
    <Menu {...props}>
      <DashboardMenuItem />
      {hasAccess(permissions, 'user.enabled') && (
        <MenuItemLink
          to={{
            pathname: '/user',
            search: getFilterSearch(identity),
          }}
          primaryText={translate('surveys.resources.users')}
          leftIcon={<UserIcon />}
        />
      )}
      {hasAccess(permissions, 'campaigns.enabled') && (
        <MenuItemLink
          to={{
            pathname: '/campaigns',
            search: getFilterSearch(identity),
          }}
          primaryText={translate('surveys.resources.campaigns')}
          leftIcon={<RssFeedIcon />}
        />
      )}
      {hasAccess(permissions, 'questions.enabled') && (
        <MenuItemLink
          to={{
            pathname: '/questions',
            search: getFilterSearch(identity),
          }}
          primaryText={translate('surveys.resources.questions')}
          leftIcon={<QuestionAnswerIcon />}
        />
      )}
      {hasAccess(permissions, 'responses.enabled')}
      {hasAccess(permissions, 'thematics.enabled') && (
        <MenuItemLink
          to={{
            pathname: '/thematics',
            search: getFilterSearch(identity),
          }}
          primaryText={translate('surveys.resources.thematics')}
          leftIcon={<EmojiSymbolsIcon />}
        />
      )}
      {hasAccess(permissions, 'coregs.enabled') && (
        <MenuItemLink
          to={{
            pathname: '/coregs',
            search: getFilterSearch(identity),
          }}
          primaryText={translate('surveys.resources.coregs')}
          leftIcon={<FeaturedPlayListIcon />}
        />
      )}
    </Menu>
  )
}

const getFilterSearch = identity => {
  let filterSearch = ''
  if (identity.role !== 'admin') {
    filterSearch = `filter=${JSON.stringify({ country: identity.country })}`
  }
  return filterSearch
}
