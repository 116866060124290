import {
  ListButton,
  SaveButton,
  Toolbar,
  TopToolbar,
  useGetIdentity,
  Create,
  SimpleForm,
  TextInput,
  required,
  TranslatableFields,
  BooleanInput,
  SelectInput,
} from 'react-admin'
import * as React from 'react'
import { useTranslate } from 'ra-core'
import PropTypes from 'prop-types'

const ThematicCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

ThematicCreateActions.propTypes = {
  basePath: PropTypes.any,
}

const ThematicCreateToolbar = props => {
  const translate = useTranslate()
  return (
    <Toolbar {...props}>
      <SaveButton
        label={translate('surveys.actions.create_thematics')}
        redirect="show"
      />
    </Toolbar>
  )
}

const ThematicCreate = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>

  return (
    <Create actions={<ThematicCreateActions />} {...props}>
      <SimpleForm toolbar={<ThematicCreateToolbar />}>
        <BooleanInput source="status" defaultValue={false} />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale={identity.country.toLowerCase()}
          source="label"
        >
          <TextInput fullWidth source="label" validate={[required()]} />
        </TranslatableFields>
        <SelectInput
          source="country"
          choices={[
            { id: 'ES', name: 'ES' },
            { id: 'IT', name: 'IT' },
            { id: 'FR', name: 'FR' },
            { id: 'EN', name: 'EN' },
          ]}
        ></SelectInput>
      </SimpleForm>
    </Create>
  )
}

export default ThematicCreate
