import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ShowButton,
  useGetIdentity,
  DeleteWithConfirmButton,
  ReferenceField,
} from 'react-admin'
//import { useTranslate } from 'ra-core'

const QuestionList = props => {
  //const translate = useTranslate()
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const questionLabel = 'label.' + identity.country.toLowerCase()
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid>
        <TextField source="id" sortByOrder="DESC" />
        <ReferenceField
          label="Thematic"
          source="thematicId"
          reference="thematics"
          link="show"
        >
          <TextField source={questionLabel} />
        </ReferenceField>
        <TextField source={questionLabel} label="Label" />
        <TextField source="responseType" />
        <TextField source="nextQuestionId" sortByOrder="DESC" emptyText="-" />
        <TextField source="fieldMD" label="Mdirector Field" />
        <TextField source="country" />
        <BooleanField source="status" looseValue={true} />
        <ShowButton />
        <DeleteWithConfirmButton cancel="Cancel" undoable={true} />
      </Datagrid>
    </List>
  )
}

export default QuestionList
