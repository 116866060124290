import { Login } from 'react-admin'
import LoginForm from './LoginForm'

const LoginPage = () => (
  <Login>
    <LoginForm />
  </Login>
)

export default LoginPage
