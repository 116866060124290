import ThematicShow from './ThematicShow'
import ThematicCreate from './ThematicCreate'
import ThematicList from './ThematicList'
import ThematicEdit from './ThematicEdit'

export default {
  show: ThematicShow,
  list: ThematicList,
  create: ThematicCreate,
  edit: ThematicEdit,
}
