import * as React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(
  theme => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    forgotButtonDiv: {
      padding: '0 1em 1em 1em',
      display: 'flex',
      justifyContent: 'end',
    },
    forgotButton: {
      fontSize: '0.65em',
    },
  }),
  { name: 'RaLoginForm' },
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const LoginForm = props => {
  const { redirectTo } = props
  const [loading, setLoading] = useSafeSetState(false)
  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()
  const history = useHistory()
  const classes = useStyles(props)

  const validate = values => {
    const errors = { email: undefined, password: undefined }

    if (!values.email) {
      errors.email = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const submit = values => {
    setLoading(true)
    login(values, redirectTo)
      .then(() => {
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          },
        )
      })
  }

  const handleForgotButtonClick = () => {
    history.push('/forgot-password')
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="email"
                name="email"
                component={Input}
                label="Email"
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
          </div>
          <div className={classes.forgotButtonDiv}>
            <Button
              onClick={handleForgotButtonClick}
              size="small"
              color="primary"
              className={classes.forgotButton}
            >
              {translate('surveys.messages.forgot_password')}
            </Button>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default LoginForm
