import * as React from 'react'
import {
  TranslatableFields,
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  useGetIdentity,
  required,
  TopToolbar,
  ListButton,
} from 'react-admin'
import * as PropTypes from 'prop-types'
import validateQuestion from './validateQuestion'

const RESPONSE_SELECT_I18N = [
  { id: 'radio', text: 'radioButton' },
  { id: 'select', text: 'selector' },
  { id: 'check', text: 'checkBoxes' },
  { id: 'datepicker', text: 'DatePicker Date' },
]

const QuestionCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

QuestionCreateActions.propTypes = {
  basePath: PropTypes.any,
}

const QuestionCreate = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const questionLabel = 'label.' + identity.country.toLowerCase()
  return (
    <Create actions={<QuestionCreateActions />} {...props}>
      <SimpleForm validate={validateQuestion}>
        <ReferenceInput
          translateChoice={false}
          label="Thematic"
          source="thematicId"
          reference="thematics"
          filter={{ country: identity.country }}
        >
          <SelectInput optionText={questionLabel} validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput
          label="Next Question"
          source="nextQuestionId"
          reference="questions"
          filter={{ country: identity.country }}
          allowEmpty
        >
          <SelectInput translateChoice={false} optionText={questionLabel} />
        </ReferenceInput>
        <BooleanInput source="status" />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale={identity.country.toLowerCase()}
          source="label"
        >
          <TextInput fullWidth source="label" validate={[required()]} />
        </TranslatableFields>
        <AutocompleteInput
          source="responseType"
          choices={RESPONSE_SELECT_I18N}
          translateChoice={false}
          optionValue="id"
          optionText="text"
          validate={[required()]}
        />
        <TextInput
          source="fieldMD"
          label="Mdirector Field"
          validate={[required()]}
        />
        <SelectInput
          source="country"
          choices={[
            { id: 'ES', name: 'ES' },
            { id: 'IT', name: 'IT' },
            { id: 'FR', name: 'FR' },
            { id: 'EN', name: 'EN' },
          ]}
          validate={[required()]}
        ></SelectInput>
      </SimpleForm>
    </Create>
  )
}

export default QuestionCreate
