import { fetchUtils } from 'react-admin'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const auth = JSON.parse(localStorage.getItem('auth'))
  if (auth) {
    options.headers.set('Authorization', `Basic ${auth.token}`)
  }

  return fetchUtils.fetchJson(url, options)
}

export default httpClient
