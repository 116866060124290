import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  TopToolbar,
  CreateButton,
  useGetIdentity,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import * as React from 'react'

const ThematicListActions = () => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <CreateButton label={translate('surveys.actions.new_thematics')} />
    </TopToolbar>
  )
}

const ThematicList = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const thematicLabel = 'label.' + identity.country.toLowerCase()
  return (
    <List
      {...props}
      actions={<ThematicListActions />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid>
        <NumberField source="id" sortByOrder="DESC" />
        <TextField source={thematicLabel} label="Label" />
        <BooleanField
          source="status"
          looseValue={true}
          valueLabelTrue="Active"
          valueLabelFalse="Not Active"
        />
        <TextField source="country" label="Country" />
        <EditButton />
        <DeleteWithConfirmButton cancel="Cancel" undoable={true} />
      </Datagrid>
    </List>
  )
}

export default ThematicList
