import { resolveBrowserLocale } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import frenchMessages from 'ra-language-french'
import italianMessages from 'ra-language-italian'
import spanishMessages from '@blackbox-vision/ra-language-spanish'
import * as domainMessages from '../i18n'

const messages = {
  en: { ...englishMessages, ...domainMessages.customEnglishMessages },
  es: { ...spanishMessages, ...domainMessages.customSpanishMessages },
  fr: { ...frenchMessages, ...domainMessages.customFrenchMessages },
  it: { ...italianMessages, ...domainMessages.customItalianMessages },
}

const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.es),
  resolveBrowserLocale(),
)

export default i18nProvider
