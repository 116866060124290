import envConfig from '../environments'

const identity = () => {
  const auth = JSON.parse(localStorage.getItem('auth'))
  const request = new Request(envConfig.backendUrl + '/identity', {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Basic ${auth.token}`,
    }),
  })
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(identity => {
      localStorage.setItem('identity', JSON.stringify(identity.data))
    })
    .catch(error => {
      throw new Error(error.message)
    })
}

export default identity
