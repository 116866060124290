import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  ReferenceField,
  EditButton,
  DeleteWithConfirmButton,
  TextInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { useGetIdentity } from 'react-admin'

const FilesFilters = [<TextInput key="cdCampaignId" source="cdCampaignId" />]

const CoregsListActions = () => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <CreateButton label={translate('surveys.actions.new_coregs')} />
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  )
}

const CoregsList = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const questionSource = 'label.' + identity.country.toLowerCase()
  return (
    <List
      {...props}
      actions={<CoregsListActions />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'ASC' }}
      filters={FilesFilters}
    >
      <Datagrid>
        <NumberField source="id" sortByOrder="DESC" />
        <ReferenceField
          label="Question text"
          source="questionId"
          reference="questions"
        >
          <TextField source={questionSource} />
        </ReferenceField>
        <ReferenceField
          label="Question field MD"
          source="questionId"
          reference="questions"
        >
          <TextField source="fieldMD" />
        </ReferenceField>
        <TextField
          label="CD Campaign"
          source="cdCampaignId"
          sortByOrder="DESC"
        />
        <TextField label="CD Affiliate" source="cdAffiliateId" />
        <BooleanField
          source="status"
          valueLabelTrue="Active"
          valueLabelFalse="Not Active"
        />
        <EditButton />
        <DeleteWithConfirmButton cancel="Cancel" undoable={true} />
      </Datagrid>
    </List>
  )
}

export default CoregsList
