import * as React from 'react'
import {
  TextField,
  TranslatableFields,
  Edit,
  ShowButton,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  useGetIdentity,
  TopToolbar,
  ListButton,
} from 'react-admin'
import * as PropTypes from 'prop-types'
import validateQuestion from './validateQuestion'
import { useTranslate } from 'ra-core'

const RESPONSE_SELECT_I18N = [
  { id: 'radio', text: 'radioButton' },
  { id: 'select', text: 'selector' },
  { id: 'check', text: 'checkBoxes' },
  { id: 'datepicker', text: 'DatePicker Date' },
]

const QuestionEditActions = ({ basePath, data }) => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
      <ShowButton
        basePath={basePath}
        label={translate('surveys.actions.show_question')}
        record={data}
      />
    </TopToolbar>
  )
}

QuestionEditActions.propTypes = {
  data: PropTypes.any,
  basePath: PropTypes.any,
}

const QuestionEdit = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const questionLabel = 'label.' + identity.country.toLowerCase()
  return (
    <Edit actions={<QuestionEditActions />} {...props}>
      <SimpleForm validate={validateQuestion}>
        <TextField source="id" disabled />
        <TextField source="thematicId" disabled />
        <ReferenceInput
          translateChoice={false}
          label="Next Question"
          source="nextQuestionId"
          reference="questions"
          filter={{ country: identity.country }}
          allowEmpty
        >
          <SelectInput optionText={questionLabel} />
        </ReferenceInput>
        <BooleanInput source="status" />
        <TranslatableFields
          locales={['es', 'en', 'it', 'fr']}
          defaultLocale={identity.country.toLowerCase()}
          source="label"
        >
          <TextInput fullWidth source="label" label="Question" />
        </TranslatableFields>
        <SelectInput
          fullWidth
          source="responseType"
          choices={RESPONSE_SELECT_I18N}
          translateChoice={false}
          optionValue="id"
          optionText="text"
        />
        <TextInput source="fieldMD" label="Mdirector Field" />
        <TextField source="country" disabled />
      </SimpleForm>
    </Edit>
  )
}

export default QuestionEdit
