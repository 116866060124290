import React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ContentAdd from '@material-ui/icons/Add'
import * as PropTypes from 'prop-types'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const AddResponseButton = ({ record }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={{
        pathname: '/responses/create',
        state: { record: { questionId: record.id } },
      }}
      label={translate('surveys.actions.add_response')}
    >
      <ContentAdd />
    </Button>
  )
}

AddResponseButton.propTypes = {
  record: PropTypes.object,
}

export default AddResponseButton
