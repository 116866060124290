const customSpanishMessages = {
  surveys: {
    language: {
      es: 'Español',
      en: 'Inglés',
      it: 'Italiano',
      fr: 'Frances',
    },
    resources: {
      campaign: 'Campaña',
      campaigns: 'Campañas',
      user: 'Usuario',
      users: 'Usuarios',
      question: 'Pregunta',
      questions: 'Preguntas',
      response: 'Respuesta',
      responses: 'Respuestas',
      coreg: 'Corregistro',
      coregs: 'Corregistros',
      thematics: 'Temáticas',
      thematic: 'Temática',
    },
    actions: {
      show_question: 'Mostrar pregunta',
      edit_question: 'Editar pregunta',
      add_response: 'Añadir respuesta',
      new_coregs: 'Añadir Coreg',
      create_coregs: 'Crear Coreg',
      new_thematics: 'Añadir Temática',
      create_thematics: 'Crear Temática',
    },
    common: {
      status: 'Estado',
      active: 'Alta',
      desactive: 'Baja',
      vip: 'Vip',
      standard: 'Standard',
      identify: 'Id',
      name: 'Nombre',
      registered: 'Registrado',
      registration_date: 'Fecha alta',
      origin: 'Origen',
      boolean: {
        yes: 'Sí',
        no: 'No',
      },
    },
    messages: {
      welcome: 'Bienvenido al administrador',
      update_success: 'La actualización se ha realizado correctamente',
      update_failure: 'Ocurrió un error al realizar la actualización',
      user_deactivated_by_admin: 'Usuario dado de baja por un administrador',
      data_loading: 'Cargando datos ...',
      data_error: 'Error cargando datos',
      chart_data_loading: 'Cargando datos del gráfico ...',
      chart_data_error: 'Error cargando datos del gráfico',
      forgot_password: '¿Olvidaste tu contraseña?',
      reset_password: 'Restablecer contraseña',
      reset_password_text:
        'Introduce el email asociado con tu cuenta y te enviaremos un email con instrucciones para restablecer tu contraseña.',
      confirm_password: 'Confirmar contraseña',
      create_password: 'Crear contraseña',
      create_password_text: 'Por favor, introduce una nueva contraseña.',
      change_password: 'Cambiar contraseña',
      change_password_success: 'Password changed successfully',
      change_password_error: 'Error on reset password',
      change_password_link_error: 'Link not valid',
      forgot_password_success:
        'An email will be sent with the instructions to reset your password',
      forgot_password_error: 'Error requesting the password change',
      send_instructions: 'Enviar instrucciones',
      exceptions: {
        ResourceUrlExistException: 'Ya existe un recurso con esta URL (%{url})',
      },
    },
    validations: {
      confirmPassword: 'Password must be the same as the confirm password',
    },
  },
}

export default customSpanishMessages
