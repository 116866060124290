import * as React from 'react'
import {
  Create,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  TopToolbar,
  ListButton,
  Toolbar,
  SaveButton,
} from 'react-admin'

import { useGetIdentity } from 'react-admin'
import * as PropTypes from 'prop-types'
import { useTranslate } from 'ra-core'

const CoregCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

const CoregsCreateToolbar = props => {
  const translate = useTranslate()
  return (
    <Toolbar {...props}>
      <SaveButton
        label={translate('surveys.actions.create_coregs')}
        redirect="coregs"
      />
    </Toolbar>
  )
}

CoregCreateActions.propTypes = {
  basePath: PropTypes.any,
}

const CoregsCreate = props => {
  const { identity, loading: identityLoading } = useGetIdentity()
  if (identityLoading) return <>Loading...</>
  const country = identity.country
  const questionSource = 'label.' + country.toLowerCase()
  return (
    <Create actions={<CoregCreateActions />} {...props}>
      <SimpleForm toolbar={<CoregsCreateToolbar />}>
        <ReferenceInput
          translateChoice={false}
          label="Question"
          source="questionId"
          reference="questions"
        >
          <SelectInput optionText={questionSource} />
        </ReferenceInput>
        <NumberInput label="CD Affiliate" source="cdAffiliateId" />
        <NumberInput label="CD Campaign" source="cdCampaignId" />
        <BooleanInput source="status" defaultValue={false} />
        <SelectInput
          source="country"
          choices={[
            { id: 'ES', name: 'ES' },
            { id: 'IT', name: 'IT' },
            { id: 'FR', name: 'FR' },
            { id: 'EN', name: 'EN' },
          ]}
        ></SelectInput>
      </SimpleForm>
    </Create>
  )
}

export default CoregsCreate
