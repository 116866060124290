import development from './development'
import preproduction from './preproduction'
import production from './production'

const envs = {
  'admin-surveys.dev.antevenio.com': development,
  'admin-surveys.pre.antevenio.com': preproduction,
  'admin.letsgosurveys.com': production,
}
const host = window.location.host
let env = envs[host]
if (!env) {
  env = development
}
export default env
