import qs from 'qs'
import { useEffect, useState } from 'react'
import httpClient from '../providers/httpClient'
import envConfig from '../environments'
import { useNotify } from 'ra-core'

export const useValidResetPasswordLink = location => {
  const notify = useNotify()

  const [isValidLink, setIsValidLink] = useState(false)
  const [isValidating, setIsValidating] = useState(true)

  const email = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).email
  const token = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).token

  useEffect(() => {
    if (!email || !token) {
      setIsValidating(false)
      notify('Link not valid', { type: 'warning', autoHideDuration: 3000 })
    } else {
      httpClient(
        `${envConfig.backendUrl}/check-password-reset-data/${email}?token=${token}`,
        {
          method: 'GET',
        },
      )
        .then(() => {
          setIsValidLink(true)
        })
        .catch(() => {
          notify('surveys.messages.change_password_link_error', {
            type: 'warning',
            autoHideDuration: 3000,
          })
        })
        .finally(() => {
          setIsValidating(false)
        })
    }
  }, [])

  return { isValidLink, isValidating }
}
